exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gioi-thieu-js": () => import("./../../../src/pages/gioi-thieu.js" /* webpackChunkName: "component---src-pages-gioi-thieu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lien-he-js": () => import("./../../../src/pages/lien-he.js" /* webpackChunkName: "component---src-pages-lien-he-js" */),
  "component---src-pages-tin-tuc-index-js": () => import("./../../../src/pages/tin-tuc/index.js" /* webpackChunkName: "component---src-pages-tin-tuc-index-js" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-san-pham-tau-khach-tender-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/san-pham/tau-khach-tender.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-san-pham-tau-khach-tender-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-1-navy-will-buy-cut-price-frigates-reverse-fleets-declining-firepower-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/1-navy-will-buy-cut-price-frigates-reverse-fleets-declining-firepower/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-1-navy-will-buy-cut-price-frigates-reverse-fleets-declining-firepower-index-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-10-norled-dat-hang-bo-sung-hai-chuyen-pha-hybrid-dien-diesel-tai-remontowa-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/10-norled-dat-hang-bo-sung-hai-chuyen-pha-hybrid-dien-diesel-tai-remontowa/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-10-norled-dat-hang-bo-sung-hai-chuyen-pha-hybrid-dien-diesel-tai-remontowa-index-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-11-bmt-ra-mat-thiet-ke-pha-sinh-thai-moi-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/11-bmt-ra-mat-thiet-ke-pha-sinh-thai-moi/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-11-bmt-ra-mat-thiet-ke-pha-sinh-thai-moi-index-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-12-le-ha-thuy-tau-tien-tri-09-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/12-le-ha-thuy-tau-tien-tri-09/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-12-le-ha-thuy-tau-tien-tri-09-index-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-2-english-north-koreas-nuclear-bomb-can-we-work-out-its-power-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/2-english-north-koreas-nuclear-bomb-can-we-work-out-its-power/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-2-english-north-koreas-nuclear-bomb-can-we-work-out-its-power-index-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-3-bao-cao-cac-hang-hang-khong-nhat-ban-dat-mua-20-chiec-lng-powered-car-carriers-cho-toyota-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/3-bao-cao-cac-hang-hang-khong-nhat-ban-dat-mua-20-chiec-lng-powered-car-carriers-cho-toyota/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-3-bao-cao-cac-hang-hang-khong-nhat-ban-dat-mua-20-chiec-lng-powered-car-carriers-cho-toyota-index-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-4-managing-human-cyber-risks-maritime-industry-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/4-managing-human-cyber-risks-maritime-industry/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-4-managing-human-cyber-risks-maritime-industry-index-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-5-du-bao-quy-iii-cho-nha-may-dong-tau-han-quoc-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/5-du-bao-quy-iii-cho-nha-may-dong-tau-han-quoc/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-5-du-bao-quy-iii-cho-nha-may-dong-tau-han-quoc-index-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-6-sovcomflot-va-rosneft-ky-thoa-thuan-cho-5-tau-cho-dau-van-chuyen-nhieu-lieu-khi-hoa-long-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/6-sovcomflot-va-rosneft-ky-thoa-thuan-cho-5-tau-cho-dau-van-chuyen-nhieu-lieu-khi-hoa-long/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-6-sovcomflot-va-rosneft-ky-thoa-thuan-cho-5-tau-cho-dau-van-chuyen-nhieu-lieu-khi-hoa-long-index-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-7-nha-cung-cap-lng-thu-tu-cho-du-sinopec-lng-da-duoc-phat-hanh-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/7-nha-cung-cap-lng-thu-tu-cho-du-sinopec-lng-da-duoc-phat-hanh/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-7-nha-cung-cap-lng-thu-tu-cho-du-sinopec-lng-da-duoc-phat-hanh-index-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-8-545-2-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/8-545-2/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-8-545-2-index-mdx" */),
  "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-9-553-2-index-mdx": () => import("./../../../src/pages/tin-tuc/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/tin-tuc/9-553-2/index.mdx" /* webpackChunkName: "component---src-pages-tin-tuc-mdx-frontmatter-slug-js-content-file-path-content-tin-tuc-9-553-2-index-mdx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

